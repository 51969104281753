import _ from 'lodash'

const getAdminItems = (user, ability) => {
  let items = [
    // {
    //   slug: 'partners',
    //   title: 'Parceiros',
    //   icon: 'fal fa-star',
    //   href: '/parceiros'
    // },
    {
      slug: 'ies',
      title: 'IES',
      icon: 'fal fa-university',
      items: [
        {
          slug: 'ies-listing',
          title: 'Todas as IES',
          href: '/ies'
        },
        {
          slug: 'ies-courses',
          title: 'Cursos',
          href: '/ies/cursos'
        },
        {
          slug: 'ies-rankings',
          title: 'Rankings de IESe',
          href: '/ies/rankings'
        }
      ]
    },
    {
      slug: 'federations',
      title: 'Federações',
      icon: 'fal fa-landmark',
      href: '/federacoes'
    },
    {
      slug: 'cores',
      title: 'Núcleos',
      icon: 'fal fa-archway',
      href: '/nucleos'
    },
    {
      slug: 'ejs',
      title: 'EJs',
      icon: 'fal fa-rocket-launch',
      href: '/ejs'
    },

    {
      slug: 'users',
      title: 'Usuários',
      icon: 'fal fa-user-group',
      items: [
        {
          slug: 'users-listing',
          title: 'Todos os usuários',
          href: '/usuarios'
        },
        {
          slug: 'users-shutdown-requests',
          title: 'Solicitações de desligamento',
          href: '/bj/usuarios/solicitacoes-de-desligamento'
        }
      ]
    },
    {
      slug: 'separator-1',
      isSeparator: true
    },
    {
      slug: 'connection-products',
      title: 'Produtos de Conexão',
      icon: 'fal fa-users-rays',
      href: '/produtos-de-conexao'
    },
    {
      slug: 'evnttz',
      title: 'Eventos',
      icon: 'fal fa-calendar-star',
      items: [
        {
          slug: 'evnttz-productor',
          title: 'Área do Produtor',
          href: '/e/produtor'
        },
        {
          slug: 'evnttz-wallet',
          title: 'Minha carteira',
          href: '/e/produtor/minha-carteira'
        },
        // {
        //   slug: 'evnttz-reports',
        //   title: 'Dados',
        //   href: '/e/dados'
        // },
        {
          slug: 'evnttz-my-tickets',
          title: 'Meus Ingressos',
          href: '/meus-ingressos'
        }
      ]
    },
    {
      slug: 'monitoring',
      title: 'Monitoramento',
      icon: 'fal fa-chart-area',
      items: [
        {
          slug: 'monitoring-federations',
          title: 'Federações',
          href: '/federacoes/monitoramento'
        },
        {
          slug: 'monitoring-cores',
          title: 'Núcleos',
          href: '/nucleos/monitoramento'
        }
      ]
    },
    {
      slug: 'seal',
      title: 'Selo',
      icon: 'fal fa-badge-check',
      href: '/selo/coletas'
      // items: [
      //   {
      //     slug: 'seal-ies',
      //     title: 'IES',
      //     href: '/ies/selo'
      //   },
      //   {
      //     slug: 'seal-federations',
      //     title: 'Federações',
      //     href: '/federacoes/selo'
      //   },
      //   {
      //     slug: 'seal-cores',
      //     title: 'Núcleos',
      //     href: '/nucleos/selo'
      //   },
      //   {
      //     slug: 'seal-ejs',
      //     title: 'EJ',
      //     href: '/ejs/selo'
      //   }
      // ]
    },
    {
      slug: 'goals-gathers',
      title: 'Coletas de Metas',
      icon: 'fal fa-bullseye-arrow',
      href: '/coletas-de-metas'
    },
    {
      slug: 'metrics-gathers',
      title: 'Coletas de Métricas',
      icon: 'fal fa-ballot-check',
      href: '/coletas-de-metricas'
    },
    {
      slug: 'tetris',
      title: 'Tetris | Conteúdos',
      icon: 'fal fa-book-open-reader',
      items: [
        // {
        //   slug: 'tetris-dashboard',
        //   title: 'Dashboard',
        //   href: '/bj/tetris'
        // },
        {
          slug: 'tetris-posts',
          title: 'Postagens',
          href: '/bj/tetris/artigos'
        },
        {
          slug: 'tetris-topics',
          title: 'Tópicos',
          href: '/bj/tetris/topicos'
        },
        {
          slug: 'tetris-authors',
          title: 'Autores',
          href: '/bj/tetris/autores'
        },
        {
          slug: 'tetris-tags',
          title: 'Tags',
          href: '/bj/tetris/tags'
        }
      ]
    },
    {
      slug: 'innovates',
      title: 'MEJ Inova',
      icon: 'fal fa-trophy',
      items: [
        {
          slug: 'innovates-projects',
          title: 'Desafios',
          href: '/mej-inova/desafios'
        }
      ]
    },
    {
      slug: 'separator-2',
      isSeparator: true
    },
    {
      slug: 'reports',
      title: 'Relatórios',
      icon: 'fal fa-file-excel',
      href: '/relatorios'
    },
    {
      slug: 'surveys',
      title: 'Pesquisas',
      icon: 'fal fa-clipboard-list-check',
      href: '/pesquisas'
    },
    {
      slug: 'notifications',
      title: 'Notificações',
      icon: 'fal fa-bell',
      href: '/notificacoes'
    },
    {
      slug: 'access',
      title: 'Acessos',
      icon: 'fal fa-person-to-door',
      href: '/acessos'
    },
    {
      slug: 'settings',
      title: 'Configurações',
      icon: 'fal fa-cog',
      items: [
        {
          slug: 'settings-home',
          title: 'Página Início',
          href: '/dashboard/configuracoes'
        },
        {
          slug: 'settings-partners',
          title: 'Parceiros',
          href: '/bj/parceiros'
        },
        {
          slug: 'settings-services',
          title: 'Serviços',
          href: '/servicos'
        },
        {
          slug: 'metas-anuais',
          title: 'Metas anuais',
          href: '/metas-anuais'
        },
        {
          slug: 'settings-courses',
          title: 'Cursos',
          href: '/ies/cursos'
        },
        {
          slug: 'settings-terms',
          title: 'Termos',
          href: '/termos'
        },
        {
          slug: 'settings-landing-pages',
          title: 'Landing Pages',
          href: '/landing-pages'
        }
      ]
    }
  ]

  return items
}

export default getAdminItems
