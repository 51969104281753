import _ from 'lodash'

const getConfig = (props = {}) => {
  const { my } = props
  const termKinds = [
    { slug: 'terms_of_use', name: 'Termos de Uso' },
    { slug: 'privacy_policy', name: 'Política de Privacidade' },
    {
      slug: 'data_retention',
      name: 'Política de Retenção e Descarte de Dados'
    },
    { slug: 'cookies_policy', name: 'Política de Cookies' },
    { slug: 'newsletter', name: 'Newsletter' },
    { slug: 'other', name: 'Outro' }
  ]
  const contentTypes = [
    // { slug: 'text', name: 'Texto' },
    { slug: 'file', name: 'Arquivo' }
    // { slug: 'newsletter', name: 'Newsletter' }
  ]

  const config = {
    basePath: '/termos',
    apiBasePath: my ? '/users/terms' : '/portal/terms',
    icon: 'file-signature',
    humanName: {
      singular: 'Termo',
      plural: 'Termos'
    },
    filter: {
      termable_type_null: true
    },
    header: {
      toolbarLinks: [
        {
          text: 'Adicionar',
          url: '/termos/novo',
          className: 'btn btn-light-primary btn-sm'
        }
      ]
    },
    contentTypes,
    getContentType: slug => _.find(contentTypes, { slug }),
    termKinds,
    getTermKind: slug => _.find(termKinds, { slug })
  }

  return config
}

export default getConfig
